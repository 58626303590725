import React from "react"
import { createMuiTheme } from "@material-ui/core/styles"
import useStorage from "../../hooks/useStorage"
import { useContext } from "react"
import grey from "@material-ui/core/colors/grey"
import usePrefersDarkMode from "../../hooks/usePrefersDarkMode"

export const gold = "#FFECB3"
export const primary = "#333" //"#FFECB3"
export const backgroundColor = "#fff"
export const foregroundColor = "#666"

const UIThemeContext = React.createContext()

const base = {
  typography: {
    fontFamily: `-apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
    Oxygen-Sans, Ubuntu, Cantarell, "Helvetica Neue", sans-serif;`,
    button: { textTransform: "none" },
    useNextVariants: true
  }
  // palette: {
  //   primary: {
  //     light: gold,
  //     main: "#000",
  //     dark: gold,
  //     contrastText: gold
  //   }
  // }
}

const baseTheme = createMuiTheme(base)

export const themes = {
  dark: createMuiTheme({
    ...base,
    palette: {
      type: "dark",
      primary: grey,
      secondary: { main: "#fff" },
      background: {
        default: "#1e1f21",
        paper: "#2c2d2f"
      }
    }
  }),
  light: createMuiTheme({
    ...base,
    palette: { primary: grey, secondary: { main: "#000" }, type: "light" }
  })
}

const _useUITheme = () => {
  const defaultTheme = usePrefersDarkMode() ? "dark" : "light"
  const [selected, setSelected] = useStorage("theme.0.2", defaultTheme)
  const theme = { ...themes[selected], name: selected }
  return [theme, setSelected]
}

export const useUITheme = () => {
  return useContext(UIThemeContext)
}

export const UIThemeContextProvider = ({ children }) => {
  const value = _useUITheme()
  return (
    <UIThemeContext.Provider value={value}>{children}</UIThemeContext.Provider>
  )
}
