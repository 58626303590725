import React, { useContext, useState } from "react"
import usePoster from "./usePoster"
import useStorage from "./useStorage"

export const getProductInfo = item => {
  const { size, framed } = item.posterData
  if (size === "18x18") {
    // base printful cost:
    // - unframed: $12.50
    // - framed: $40.00
    const basePrice = 35
    const framePrice = 30
    return !framed
      ? { variantID: 6242, price: basePrice, framePrice }
      : { variantID: 4656, price: basePrice + framePrice, framePrice }
  } else if (size === "18x24") {
    // base printful cost:
    // - unframed: $13.00
    // - framed: $42.50
    const basePrice = 40
    const framePrice = 36
    return !framed
      ? { variantID: 1, price: basePrice, framePrice }
      : { variantID: 3, price: basePrice + framePrice, framePrice }
  } else {
    console.error("Invalid product passed...", item)
    throw new Error("Invalid product passed.")
  }
}

const getTotal = items => {
  return items.reduce((total, item) => {
    return total + item.price * item.quantity
  }, 0)
}

const CartContext = React.createContext()

const _useCart = () => {
  const [poster, updatePoster] = usePoster()
  const [cartItems, setCartItems] = useStorage("cart.1.1", {})
  const [selectedItemIndex, setSelectedItemIndex] = useState(0)

  console.log({ cartItems })

  const cartItemsList = Object.values(cartItems).map(item => ({
    ...item,
    ...getProductInfo(item)
  }))

  const updateItem = (id, posterData, qty) => {
    const item = cartItems[id]
    posterData = { ...item.posterData, ...posterData }
    const nextItem = {
      ...item,
      quantity: qty !== undefined ? qty : item.quantity,
      posterData
    }
    setCartItems({ ...cartItems, [id]: nextItem })
    const index = cartItemsList.findIndex(item => id === item.id)
    setSelectedItemIndex(index)
  }

  const addItem = (posterData, quantity = 1) => {
    const cartId = `poster-${new Date().getTime()}`
    const item = { id: cartId, quantity, posterData: { ...posterData, cartId } }
    setCartItems({
      ...cartItems,
      [item.id]: item
    })
    updatePoster({ cartId })
    setSelectedItemIndex(cartItemsList.length)
  }

  const removeItem = id => {
    const nextCartItems = { ...cartItems }
    delete nextCartItems[id]
    updatePoster({ cartId: null })
    setCartItems(nextCartItems)
  }

  const clear = () => {
    setCartItems({})
  }

  const getCartItemByPoster = posterData => {
    return Object.values(cartItems).find(item => posterData.cartId === item.id)
  }

  const total = getTotal(cartItemsList)
  const itemCount = cartItemsList.length

  const selectedItem = itemCount > 0 ? cartItemsList[selectedItemIndex] : null
  const selectItem = index => setSelectedItemIndex(index)

  return {
    cartItems: cartItemsList,
    selectedItem,
    selectedItemIndex,
    total,
    itemCount,
    updateItem,
    addItem,
    clear,
    removeItem,
    selectItem,
    getCartItemByPoster
  }
}

export const CartContextProvider = ({ children }) => {
  const value = _useCart()
  return <CartContext.Provider value={value}>{children}</CartContext.Provider>
}

const useCart = () => useContext(CartContext)

export default useCart
