import ReactDOM from "react-dom"
import wrapRoot from "./src/utils/wrapRoot"

export const wrapRootElement = wrapRoot

// TODO: don't do this once this issue is resolved: https://github.com/gatsbyjs/gatsby/issues/17914
export function replaceHydrateFunction() {
  return (element, container, callback) => {
    ReactDOM.render(element, container, callback)
  }
}
