import { useState, useEffect } from "react"

const createMemStorage = () => {
  const data = {}
  const setItem = (key, value) => (data[key] = value)
  const getItem = key => data[key]
  return { getItem, setItem }
}

const memStorage = createMemStorage()

const getStorage = () => {
  if (typeof window !== `undefined`) {
    return localStorage
  } else {
    return memStorage
  }
}

const getValueSafe = (key, defaultValue) => {
  const storage = getStorage()
  if (storage.getItem(key)) {
    try {
      return JSON.parse(storage.getItem(key))
    } catch (error) {
      return defaultValue
    }
  }
  return defaultValue
}

const defaultReducer = value => value

const useStorage = (key, defaultValue, persistReducer = defaultReducer) => {
  const storage = getStorage()
  const [value, _setValue] = useState(getValueSafe(key, defaultValue))

  // save on change
  useEffect(() => {
    storage.setItem(key, JSON.stringify(persistReducer(value)))
  }, [value])

  const setValue = data => _setValue(data)

  return [value, setValue]
}

export default useStorage
