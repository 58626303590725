import React from "react"
import "../components/layout.css"
import "../assets/fonts/stylesheet.css"

import { ThemeProvider } from "styled-components"
import { ApolloProvider } from "@apollo/react-hooks"
import { MuiThemeProvider } from "@material-ui/core/styles"

import client from "../client"
import { PosterContextProvider } from "../hooks/usePoster"
import { CartContextProvider } from "../hooks/useCart"
import { useUITheme, UIThemeContextProvider } from "../components/ui/theme"

const ThemeAdaptorContextProvider = ({ children }) => {
  const [theme] = useUITheme()
  return (
    <MuiThemeProvider theme={theme}>
      <ThemeProvider theme={theme}>{children}</ThemeProvider>
    </MuiThemeProvider>
  )
}

const wrapRoot = ({ element }) => (
  <ApolloProvider client={client}>
    <UIThemeContextProvider>
      <PosterContextProvider>
        <ThemeAdaptorContextProvider>
          <CartContextProvider>{element}</CartContextProvider>
        </ThemeAdaptorContextProvider>
      </PosterContextProvider>
    </UIThemeContextProvider>
  </ApolloProvider>
)

export default wrapRoot
