import React, { useContext, useEffect } from "react"

import hash from "object-hash"
import pick from "lodash/pick"
import useStorage from "./useStorage"
import { getQueryString } from "../components/utils/url"
import { examplePosters } from "../components/promo/data"

const PosterContext = React.createContext()

export const getPosterID = posterState => {
  const keys = [
    "textTop",
    "size",
    "textBottom",
    "showBorder",
    "showSecondaryRoads",
    "foregroundColor",
    "backgroundColor",
    "icon",
    "pitch",
    "zoom",
    "textLayout",
    "textStyle",
    "banana"
  ]
  const {
    location: { lat, lng }
  } = posterState
  const data = {
    ...pick(posterState, keys),
    lat,
    lng,
    framed: posterState.framed ? "yes" : "no"
  }
  return hash(data)
}

export const getDefaultPoster = () => ({ ...examplePosters[0] })

const _usePoster = initialState => {
  const [posterData, setPosterData] = useStorage(
    "poster.0.6",
    initialState || getDefaultPoster()
  )

  const updatePoster = (data, reset = false) => {
    const nextPoster = !reset ? { ...posterData, ...data } : data
    setPosterData(nextPoster)
  }

  useEffect(() => {
    if (typeof window !== `undefined`) {
      window.posterState = JSON.stringify(posterData, null, 2)
      if (!window.showPrint)
        window.showPrint = () => {
          window.location = `/print?${getQueryString(posterData)}`
        }
    }
  }, [posterData])

  return [posterData, updatePoster]
}

export const PosterContextProvider = ({ children }) => {
  const value = _usePoster()
  return (
    <PosterContext.Provider value={value}>{children}</PosterContext.Provider>
  )
}

const usePoster = () => useContext(PosterContext)

export default usePoster
